.footerWrapper {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  .bottomNavigation {
    display: flex;
    justify-content: center;
    height: 70px;
    padding-top: 0.2rem;
    background-color: transparent;
  }

  .bottomNavigationAction {
    flex: 1;
    margin: 0 10px;
    width: auto;
    min-width: auto;
    border-radius: 40px 40px 0 0;
    padding: 8px 4px;
    position: relative;
  }
}
