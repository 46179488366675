.pricingPage {
  position: relative;
  overflow: hidden;
}

.pricingItem {
  position: relative;
  margin: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 24px;
  transition: all 0.3s;
  min-height: 440px;

  .pricingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid #ffffff29;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    min-height: 82px;

    p {
      font-size: 0.7rem;
      font-weight: 100;
    }

    .price {
      display: flex;
      flex-direction: column;
      align-items: center;

      h6 {
        font-size: 1rem;
        font-weight: 100;
      }

      h2 {
        font-size: 2rem;
        font-weight: 300;
        margin-top: unset;
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    min-height: 240px;
    border-bottom: 1px solid #ffffff29;
    padding-bottom: 1rem;
    margin-bottom: 1rem;

    div {
      margin-bottom: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        font-weight: 100;
        font-size: 0.8rem;
      }
    }

    h6 {
      transform: rotate(90deg);
    }
  }

  .action {
  }
}

.suggested {
  border: 2px solid #00aeef;

  &::after {
    content: "Recommended";
    position: absolute;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;
    font-size: 0.9rem;
    background-color: #00aeef;
    padding: 0.5rem 0.7rem;
    border-radius: 0.5rem;
  }
}
