// ----------- Agent Drawer -----------

@import "../../utils/variables/variables.scss";

.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  filter: blur(2px);
}

.drawer {
  box-sizing: border-box;
  height: calc(100dvh);
  left: 0;
  top: 0;
  box-shadow: none;
  border: none;
  width: 270px;
  z-index: 2000;
  overflow: hidden;
  position: fixed;

  h4 {
    font-weight: 500;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid;
  }
}

@media (max-width: 1100px) {
  .drawer {
    box-sizing: border-box;
    height: calc(100dvh - 5rem);
    left: 0;
    top: 5rem;
    box-shadow: none;
    border: none;
    width: 270px;
    z-index: 2000;
    border-radius: 0px 13px 13px 0px;
    overflow: hidden;
    position: fixed;
  }
}

// ----------- Agent Header -----------

.agentHeader {
  margin: 1.5rem 0 2.5rem;
  transition: all 0.4s;

  p {
    margin-top: 0.4rem !important;
    font-size: 1.1rem !important;
    line-height: 1.5 !important;
    text-align: center !important;
  }
}

@media (max-width: 1100px) {
  .agentHeader {
    margin: 0 0 3rem !important;

    h1 {
      text-align: left;
      font-size: 1.5rem !important;
    }

    p {
      text-align: left !important;
      margin-top: 0.6rem !important;
      font-size: 1rem !important;
      font-weight: 100;
    }
  }
}

// ----------- Agent List -----------

.agentsList {
  position: relative;
  z-index: 1;
  margin: auto;
  padding-top: 1rem;
  display: grid;
  transition: all 0.9s;

  .agent {
    padding: 0.5rem;
    padding-bottom: 1.2rem;
    border-radius: 12px;
    box-sizing: border-box;
    transition: all 0.4s;
    position: relative;
    min-height: 230px;

    .agentCardHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 48px;
        height: 48px;
      }

      span {
        padding: 0.1rem 0.6rem;
        border-radius: 4px;
        font-weight: 100;
      }
    }

    .agentComingSoon {
      position: absolute;
      top: -25px;
      left: 45%;
      transform: translateX(-42%);
      padding: 0 0.5rem;
      border-radius: 6px;
      line-height: 2;
      width: 60%;
    }

    h3 {
      margin-top: 1.2rem;
      font-size: 0.92rem;
    }

    p {
      font-weight: 300;
      font-size: 0.79rem;
      margin-top: 0.1rem;
      text-align: left;
    }
  }
}

@media (max-width: 1200px) {
  .agentsList {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media (max-width: 900px) {
  .agentsList {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

// ----------- Agent Suggestions -----------

.agentSuggestions {
  width: 50%;
  margin-top: 2rem;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.7rem;
    border-radius: 6px;
    margin-bottom: 0.5rem;
    border: 1px solid #4e4e4e;
    cursor: pointer;
    transition: all 0.4s;

    p {
      font-size: 0.85rem;
      margin: 0;
    }

    &:hover {
      border: 1px solid #a8a8a8;
    }
  }
}

.agentResponseSuggestions {
  width: 100%;

  .agentSuggestions {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .agentSuggestions {
    width: 100%;
  }
}

// ----------- Agent Chat Input -----------

.agentInputWrapper {
  width: 60%;

  .select_network_box {
    width: 100%;
    height: 40px;
    border-radius: 45px;
    margin-bottom: 1rem;

    select {
      border-radius: 45px;
      width: 99%;
      height: 100%;
      color: #fff;
      border: none;
      outline: none;
      resize: none;
      font-size: 0.9rem;
      padding: 0.1rem 2rem;
      font-family: poppins;
      font-weight: 100;
      box-sizing: border-box;
      background-color: transparent;

      &::placeholder {
        font-weight: 100;
        color: rgb(185, 185, 185);
      }

      &:focus {
        border: none;
        outline: none;
      }

      option {
        color: #000;
      }
    }
  }

  .agent_input {
    position: relative;
    overflow: hidden;
    border-radius: 45px;

    .file_picker {
      position: absolute;
      left: 15px;
      bottom: 12px;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      * {
        cursor: pointer;
      }

      input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 1;
      }
    }

    textarea {
      color: #fff;
      border: none;
      outline: none;
      resize: none;
      font-size: 0.9rem;
      padding: 1.3rem 1rem 0.5rem;
      font-family: Poppins, sans-serif;
      background-color: transparent;
      min-height: 60px;
      max-height: 220px;
      overflow-y: auto;

      &::placeholder {
        color: #cccccc;
        font-weight: 100;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 0;
      transition: all 0.4s;

      &:hover {
        background-color: #00aeef;
      }
    }
  }
}

.agentLargeInputWrapper {
  width: 100%;
}

@media (max-width: 1100px) {
  .agentInputWrapper {
    width: 100%;

    .agent_input {
      position: relative;
      border-radius: 25px;
      overflow: hidden;
      min-height: 230px;
      margin-bottom: 0.3rem !important;

      textarea {
        width: 100% !important;
        font-size: 0.9rem;
        margin-bottom: 50px;
        height: 230px !important;
        max-height: none !important;
        margin-left: 0 !important;
        padding: 1rem !important;
      }

      .file_picker {
        position: absolute;
        left: auto;
        right: 60px;
        bottom: 12px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          z-index: 1;
        }
      }

      button {
        right: 10px;
        top: auto;
        bottom: 12px;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        min-width: auto !important;
        border: none !important;
      }
    }
  }
}

// ----------- Agent Response -----------

.response_container {
  height: 100%;
  padding: 1rem;

  .response_content_container {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      width: 100%;
      font-size: 0.9rem;
      line-height: 1.8;
      padding: 0;
    }

    .actions {
      margin: 1rem 0 3rem;
    }
  }

  .response_component_container {
    padding-left: 3rem;
    position: relative;

    .sidebar_component {
      position: sticky;
      top: 10px;
    }
  }

  .component_wrapper {
    width: 100%;
    margin-top: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

    h3 {
      margin: 0;
      padding: 0.7rem 0;
    }

    .component_content {
      border-radius: 0 0 10px 10px;
      overflow: hidden;
      min-height: 200px;

      .component_content_table {
        td {
          font-weight: 100;
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .response_container {
    padding: 0;
    padding-top: 1rem;

    .response_content_container {
      .actions {
        margin: 1rem 0 2rem;
      }
    }

    .response_component_container {
      padding-left: 0;
    }

    .component_wrapper {
      margin-top: 1.5rem;
    }
  }
}

// ----------- Agent Info -----------

.agentInfo {
  margin-top: 1rem;
  padding: 1rem !important;
  border-radius: 8px;
  border: 1px solid rgb(102, 102, 102);
  position: relative;

  p {
    font-size: 0.95rem;
    line-height: 1.75;
    font-weight: 100;
  }

  .agentInfoMoreBtn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0.3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.agentInfoContent {
  display: flex;
  align-items: flex-start;

  .agentInfoText {
    padding: 0.5rem;
    line-height: 1.8;
    font-size: 0.95rem;
    font-weight: 300;
  }
}

@media (max-width: 1100px) {
  .agentInfoContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    ul {
      margin: 0;
      padding: 0 !important;
    }

    .agentInfoText {
      padding: 0;
    }
  }
}

// ----------- Agent Responsive Info -----------

.agentResponsiveInfo {
  margin-top: 1rem;
  padding: 0.5rem !important;
  border-radius: 8px;
  position: relative;

  .agentInfoMoreBtn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0.3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.agentInfoText {
  padding-bottom: 2rem;
}

// ----------- Agent Tabs Wrapper -----------

.componentsTabsWrapper {
  display: flex;
  justify-content: space-between;

  .componentsTab {
    flex: 1;
    padding: 0 1.3rem;
    min-width: auto;
  }
}
