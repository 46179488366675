.linechart_tooltip {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;
  border: none;
  box-shadow: none;

  h6 {
    font-weight: 100;
    font-size: 0.7rem;
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.4rem;
    width: 100%;

    h4,
    h5 {
      font-size: 0.65rem;
      font-weight: 300;
    }
  }
}

.agent_response_chart_tooltip {
  min-width: 150px;
}

.networth_linechart_tooltip {
  min-width: 180px;
}

.bubbleChart {
  position: relative;

  &::before {
    content: "Sell";
    position: absolute;
    left: 5px;
    bottom: 50px;
    transform: translate(0, -50%);
    font-size: 1rem;
    font-weight: 500;
    color: #fa637a;
  }

  &::after {
    content: "Buy";
    position: absolute;
    left: 5px;
    top: 20px;
    transform: translate(0, -50%);
    font-size: 1rem;
    font-weight: 500;
    color: #3fa153;
  }
}

.gradientBorder {
  position: absolute;
  left: 13px;
  top: 45px;
  width: 11px;
  height: 75%;
  border-radius: 4px;
  background: linear-gradient(
    0deg,
    rgba(250, 99, 122, 1) 0%,
    rgba(47, 219, 125, 1) 100%
  );
}

.imageOverlay {
  position: absolute;
  display: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

#crypto-insight-chart {
  .apexcharts-point-annotations {
    transition: all ease-in 0.3s;
    .apexcharts-point-annotation-label {
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      min-width: 140px;
      border: none;
      box-shadow: none;
    }
  }
}
