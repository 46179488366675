@import "../../../utils/variables/variables.scss";

.icon {
  position: absolute;
  right: 0;
  bottom: 0;
  filter: blur(2px);
}

.drawer {
  box-sizing: border-box;
  height: calc(100dvh - 5rem);
  left: 0;
  top: 5rem;
  box-shadow: none;
  border: none;
  width: 240px;
  z-index: 2000;
  border-radius: 0px 13px 13px 0px;
  overflow: hidden;
  position: fixed;

  .eth_icon {
    position: absolute;
    bottom: -20px;
    right: 5px !important;
    transform: rotate(0deg);
  }

  h4 {
    font-weight: 500;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid;
  }
}
