.assets_list {
  flex: 1;
  overflow: hidden;
  position: relative;
  padding-bottom: 2rem !important;

  .assets_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.4rem;
    }

    h4 {
      font-size: 1.4rem;
      font-weight: 500;
      display: flex;
      align-items: baseline;

      sub {
        font-weight: 100;
        font-size: 0.7rem;
        margin-left: 0.4rem;
      }
    }
  }

  .asset_card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    border-radius: 8px;
    margin: 5px 0;
    flex: 1;

    h6 {
      flex: 1;
      font-weight: 100;
      font-size: 0.8rem;
      display: flex;
      align-items: baseline;
    }
  }
}

.assets_modal {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 350px;
  max-width: 500px;
  box-shadow: 24;
  padding: 1rem;
  border-radius: 8px;
  transform: translate(-50%, -50%);

  .assets_modal_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .assets_modal_description {
    padding: 1rem;
    border-radius: 8px;

    span {
      font-weight: 100;
    }
  }

  .assets_modal_info {
    padding: 1rem;
    border-radius: 8px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        display: flex;
        align-items: baseline;

        sub {
          margin-left: 0.3rem;
        }
      }
    }
  }

  h2 {
    font-weight: 100;
  }
}

.connect_wallet_wrapper,
.wallet_data_wrapper {
  border-radius: 8px;
  padding: 2rem 1rem;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
}

.manually_options_wrapper {
  max-width: 500px;
  border-radius: 8px;
  margin: auto;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 0.6rem;

    &:last-of-type {
      margin-top: 1rem;
    }

    p {
      display: flex;
      align-items: center;
      font-weight: 100;

      svg {
        margin-right: 0.7rem;
      }
    }
  }
}

.assetHeaderCard {
  border-radius: 8px;
  padding: 1rem 1rem 0.5rem;
  margin-top: 0.2rem !important;

  h2 {
    font-weight: 100;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;

    h4 {
      font-size: 1.7rem;
      font-weight: 900;
    }

    h5 {
      font-size: 1.2rem;
    }

    h6 {
      font-size: 0.8rem;
      font-weight: 100;
    }
  }
}

.networthChart {
  border-radius: 8px;
  margin-top: 1rem;
}

.walletWrapper {
  border-radius: 8px;
  padding: 1rem;

  .walletHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .walletHeaderInfo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      h2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
      }

      img {
        border-radius: 10px;
        margin-right: 10px;
      }
    }
  }
}

.asset_image {
  width: 31px;
  height: 31px;
  position: relative;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 0.7rem;

  .asset_image_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .asset_image_logo {
    position: absolute;
    width: 20px;
    height: 20px;
    left: -10px;
    top: -4px;
    border-radius: 50%;
    padding: 0.1rem;
    object-fit: contain;
    background-color: #353535;
    box-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }
}
