// dark palette
$primary-dark: #00d9a5;
$secondary-dark: #adffd2;
$text-primary-dark: #ffffff;
$text-secondary-dark: #ffffff;
$bg-color-dark: #0a0e0d;
$gray-primary-dark: rgba(217, 215, 215, 0.2);

// light palette
$primary-light: #00ccb4;
$secondary-light: #00d9a5;
$text-primary-light: #033127;
$text-secondary-light: #31574c;
$bg-color-light: #ffffff;

// general
$bg-gray: rgba(144, 144, 144, 0.19);
$green-dark: #048e74;
