.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 60px;
  margin: 1.5rem auto;
  transform: rotate(0);

  img {
    width: 90px;
  }
}
