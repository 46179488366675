.walletsList {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.5rem;
    margin-bottom: 0.4rem;

    img {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      border-radius: 8px;
      background-color: #fff;
      padding: 0.2rem;
    }
  }
}

.selectedWalletWrapper {
  max-width: 350px;

  .selectedWalletHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      display: flex;
      align-items: center;

      img {
        margin-right: 0.5rem;
      }
    }
  }

  p {
    max-width: 350px;
    font-size: 0.8rem;
    font-weight: 100;
    margin-top: 1rem;
  }
}

.walletCard {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  .walletCardHeader {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      &:first-of-type {
        h3 {
          display: flex;
          align-items: center;

          img {
            margin-right: 0.5rem;
          }
        }

        p {
          margin-top: 0.5rem;
        }
      }

      &:last-of-type {
        padding: 1rem 1.8rem;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.hideWalletsList {
  opacity: 0;
  transform: translateX(-100%);
  height: 0;
  overflow: hidden;
  transition:
    opacity 0.3s,
    transform 0.5s;
}

.showWalletsList {
  opacity: 1;
  transform: translateX(0);
  height: auto;
  overflow: hidden;
  transition:
    opacity 0.3s,
    transform 0.5s;
}

.hideSelectedWallet {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateX(100%);
  transition:
    opacity 0.3s,
    transform 0.5s;
}

.showSelectedWallet {
  opacity: 1;
  height: auto;
  overflow: hidden;
  transform: translateX(0);
  transition:
    opacity 0.3s,
    transform 0.5s;
}

.walletTransactionCard {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #555;
  padding-bottom: 15px;

  .walletTransactionCard_value {
    flex: 1;
    font-weight: 500;
  }

  .walletTransactionCard_date {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 1100px) {
  .walletCard {
    .walletCardHeader {
      flex-direction: column;

      div {
        &:first-of-type {
          width: 100%;

          p {
            margin-top: 0.5rem;
            inline-size: 100%;
            overflow-wrap: break-word;
          }
        }

        &:last-of-type {
          width: 100%;
          margin-top: 2rem;
          padding: 1rem 1.8rem;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }

  .walletTransactionCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #555;
    padding-bottom: 15px;

    .walletTransactionCard_value {
      flex: 1;
      width: 100%;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    div {
      width: 100%;
      margin-bottom: 1rem;

      span {
        word-break: break-all;
      }
    }

    .walletTransactionCard_date {
      flex: 1;
      width: 100%;
      justify-content: flex-start;
    }
  }
}
