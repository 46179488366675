.coin_wrapper {
  display: flex;
  align-items: center;
  padding: 0.65rem 0.5rem;
  border-radius: 4px;
  width: 116px;
  cursor: pointer;

  div {
    display: flex;
    flex-direction: column;
    margin-left: 0.3rem;

    p {
      font-size: 11px;
      font-weight: 300;
      line-height: 1;
      margin: 0 !important;
    }

    span {
      font-weight: 100;
      font-size: 8.5px;
      margin: 0 !important;
      white-space: nowrap;
    }
  }

  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }
}

@media (max-width: 1100px) {
  .coin_wrapper {
    width: 105px;
    padding: 0.45rem 0 0.45rem 0.25rem;
    margin-bottom: 0.2rem;

    div {
      margin-left: 0.3rem;

      p {
        font-size: 12px;
      }

      span {
        font-size: 9px;
      }
    }

    img {
      width: 26px;
      height: 26px;
    }
  }
}
