:root {
  --ruby: #e22653;
  --grey: #999;
  --dark-grey: #666;
  --light-grey: #ccc;
  --cream: #f9f7ed;
  --transparent-white: #ffffffcc;
  --transition: all ease-out 300ms;
  --shadow: 0 1px 5px var(--dark-grey);
  --hover-opacity: 0.7;
  --stage-padding: 8px;
  --panels-width: 350px;
  --border-radius: 3px;
}
.sigma-mouse {
  position: relative !important;
}
.controls {
  position: absolute;
  bottom: var(--stage-padding);
  left: var(--stage-padding);
}
.panels {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  max-height: calc(100vh - 2 * var(--stage-padding));
  overflow-y: auto;
  padding: var(--stage-padding);
  scrollbar-width: thin;
}
.panel-card {
  margin-bottom: 0.5rem !important;
}
.overflow-tab {
  height: 250px;
  overflow-y: scroll;
}
/* scrollbar */
.infinite-scroll-component {
  overflow-x: hidden !important;
}

.infinite-scroll-component::-webkit-scrollbar {
  width: 6px;
}

.infinite-scroll-component::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(195, 171, 228, 0.3);
}

.infinite-scroll-component::-webkit-scrollbar-thumb {
  background-color: #e18090;
  border-radius: 10px;
}

.col::-webkit-scrollbar {
  width: 6px;
}
.col::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(195, 171, 228, 0.3);
}
.col::-webkit-scrollbar-thumb {
  background-color: #e18090;
  border-radius: 10px;
}
/* input on panel */
.caption-row input[type="checkbox"] {
  display: none;
}
.caption-row input[type="checkbox"]:not(:checked) + label {
  color: var(--dark-grey);
}
.caption-row input[type="checkbox"]:not(:checked) + label .circle {
  background-color: white !important;
}
.caption-row label {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.caption-row label:hover {
  opacity: var(--hover-opacity);
}
.caption-row label .circle {
  flex-shrink: 0;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border-radius: 1.2em;
  vertical-align: middle;
  box-sizing: border-box;
  background-color: var(--dark-grey);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 0.2em;
  transition: var(--transition);
  border: 3px solid var(--dark-grey);
}
.caption-row label .node-label {
  flex-grow: 1;
}
.caption-row label .bar {
  position: relative;
  background: var(--light-grey);
  height: 3px;
  margin-bottom: 0.2em;
}
.caption-row label .bar .inside-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--dark-grey);
  transition: var(--transition);
}

/* sigma container */
.sigmaContainer {
  height: 616px !important;
  background-color: transparent !important;
}

/* controlers */
div.ico > button {
  display: block;
  position: relative;
  font-size: 1.5em;
  width: 2em;
  height: 2em;
  border-radius: var(--border-radius);
  box-shadow: var(--shadow);
  color: #00d9a5;
  background: transparent;
  border: none;
  outline: none;
  margin-top: 0.2em;
  cursor: pointer;
  box-shadow: none;
}
div.ico > button:hover {
  color: #7cccb7;
}
div.ico > button > * {
  position: absolute;
  inset: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.react-switch-handle {
  height: 15px !important;
  margin-top: 0px;
}
.react-switch {
  margin-right: 1rem;
}

.cluster-root {
  display: flex;
  margin-bottom: 0.5rem;
}

/* SEARCH FIELD */
.search-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 40%;
  z-index: 100;
  box-shadow: 0px 0px 15px -5px #00d9a65b;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.search-wrapper > input[type="search"] {
  width: calc(100%);
  height: 2.5em;
  box-shadow: var(--shadow);
  border: none;
  outline: none;
  padding: 0.8em 1em 1em 2.8em;
  font-family: Poppins, Lato, sans-serif;
  font-size: 1em;
  background-color: transparent;
  font-weight: 100;
  border-radius: var(--border-radius);
}
.search-wrapper > input[type="search"]::placeholder {
  font-size: 0.8rem;
  color: #7cccb7;
}
.search-wrapper > .icon {
  color: #7cccb7;
  position: absolute;
  width: 1em;
  height: 1em;
  top: 0.7rem;
  left: 0.8rem;
}

.react-sigma-controls {
  background-color: #0a0e0d !important;
  box-shadow: 0px 0px 15px -5px #00d9a65b !important;
  border-radius: var(--border-radius) !important;
  border: none !important;
  left: 10px !important;
  bottom: 10px !important;
}

.react-sigma-controls .react-sigma-control {
  background-color: transparent !important;
}

.react-sigma-controls .react-sigma-control button {
  color: #7cccb7;
  background-color: transparent !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.react-sigma-controls .react-sigma-control button:hover {
  background-color: #66666650 !important;
}
