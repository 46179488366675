.footer_wrapper {
  position: relative;
  padding-bottom: 6rem !important;
  z-index: 100;
}

.stars {
  position: absolute;
  right: 0%;
  bottom: 25%;
  filter: blur(2px);
}

.bIcon {
  position: absolute;
  left: -30px;
  top: 30%;
  transform: rotate(-5deg);
  filter: blur(3px);
}

.footerWrapper {
  padding: 0 3rem;

  .footerTitle {
    font-weight: 300;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #5f5f5f;
    min-height: 35px;
  }

  .footerInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 2rem;

    img {
      width: 160px;
      height: auto;
      margin-bottom: 1.5rem;
    }

    p {
      line-height: 1.8;
      font-weight: 100;
    }
    .smsLogo {
      width: 200px;
      height: auto;
    }
  }

  .footerContact {
    padding-top: 0.5rem;

    p {
      display: flex;
      align-items: flex-start;
      margin-bottom: 1rem;
    }
  }

  .footerLinks {
    padding: 0 0.2rem;

    .footerPagesLinks {
      padding-top: 0.5rem;

      a {
        display: block;
        width: 100%;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0.6rem 0;
      }

      span {
        margin: 0 0.8rem;
        font-weight: 100;
      }

      a {
        color: #00aeef;
        text-align: center;
        text-decoration: none;
        font-weight: 300;
      }
    }
  }

  .footerSocialMedia {
    padding: 0 0.2rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      a {
        width: 100%;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;

        p {
          margin-left: 1.2rem;
        }
      }
    }
  }

  .footerDisclaimer {
    padding: 0 0.2rem;

    p {
      padding-top: 1rem;
    }
  }
}

.footerSvgWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  span {
    width: 100%;
    text-align: center;
    font-weight: 100;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  img {
    position: absolute;

    &:nth-of-type(1) {
      right: -16px;
      bottom: -16px;
      z-index: 1;
    }

    &:nth-of-type(2) {
      right: -16px;
      bottom: -16px;
      z-index: 0;
    }

    &:nth-of-type(3) {
      left: -16px;
      bottom: -16px;
      z-index: 1;
    }

    &:nth-of-type(4) {
      left: -16px;
      bottom: -16px;
      z-index: 0;
    }
  }
}

@media (max-width: 1100px) {
  .footerSvgWrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    span {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
      font-weight: 100;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    img {
      display: none;
    }
  }
}

@media (max-width: 1100px) {
  .bIcon {
    position: absolute;
    left: -30px;
    top: 80%;
    transform: rotate(-5deg);
    filter: blur(3px);
    z-index: -1;
  }

  .footerWrapper {
    padding: 0;

    .footerInfo {
      align-items: center;
      padding-right: 0;

      img {
        width: 160px;
        height: auto;
        margin-bottom: 0rem;
      }

      p {
        line-height: 1.8;
        font-weight: 100;
      }
    }

    .footerContact {
      padding-right: 0;

      p {
        display: flex;
        align-items: flex-start;
        margin-top: 1.5rem;
        font-size: 0.85rem;
        font-weight: 100;
      }
    }

    .footerLinks {
      margin-top: 3rem;

      .footerPagesLinks {
        a {
          padding: 0.5rem 0;
        }
      }
    }

    .footerSocialMedia {
      margin-top: 3rem;
    }

    .footerDisclaimer {
      margin-top: 3rem;

      p {
        padding-top: 0rem;
      }
    }
  }
}
