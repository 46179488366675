.subscriptionWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 5rem;

  h2 {
    font-weight: 100;
    margin-top: 0.3rem;
  }
}

.subscriptionContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.featuresWrapper {
}
