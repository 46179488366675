@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Light.otf");
  font-weight: 100;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Regular.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins-Bold.otf");
  font-weight: 700;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins";
}

#root_wrapper {
  z-index: 1;
}

a {
  text-decoration: none;
}

strong {
  font-family: "Poppins" !important;
}

button {
  text-transform: none !important;
}

.formik-error {
  font-size: 0.7rem !important;
  margin-top: 0.2rem !important;
}

.glass-background {
  backdrop-filter: saturate(180%) blur(10px);
}

.default-card-style {
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 8px;
}

.strong-glass-background {
  backdrop-filter: saturate(180%) blur(15px);
  background-color: rgba(0, 0, 0, 0.3);
}

.glass-dark-background {
  backdrop-filter: saturate(180%) blur(10px);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.analytics-card {
  background: #111;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.top-zindex {
  position: relative;
  z-index: 2002;
}

.infinite-scroll-component {
  overflow-x: visible !important;
}

.pattern_1 {
  width: 235px;
  height: 235px;
  position: absolute;
  z-index: 0 !important;
  filter: blur(50px);
  border-radius: 50%;
  background: #00aeef;
  transition: all 0.9s;
}

.pattern_2 {
  width: 235px;
  height: 235px;
  background: rgba(0, 174, 239, 0.54);
  position: absolute;
  border-radius: 50%;
  filter: blur(50px);
  z-index: 0 !important;
  transition: all 0.9s;
}

.pattern_3 {
  width: 90px;
  position: absolute;
  filter: blur(4px);
  z-index: 0;
  transition: all 0.9s;
}

.pattern_4,
.pattern_5 {
  width: 20px;
  height: 20px;
  background: #00aeef;
  position: absolute;
  border-radius: 50%;
  filter: blur(2px);
  z-index: 0 !important;
  transition: all 0.9s;
}

.pattern_6 {
  width: 120px;
  height: 60px;
  border-top-left-radius: 110px;
  border-top-right-radius: 110px;
  border: 2px solid #00aeef;
  border-bottom: 0;
  filter: blur(2px);
  position: absolute;
  z-index: 0 !important;
  transition: all 0.9s;
}

.modalActions {
  margin-top: 1.5rem;
}

.modalActions button:first-of-type {
  text-transform: none;
  border-radius: 30px;
  padding: 0.3rem 1.2rem;
}

.modalActions button:last-of-type {
  text-transform: none;
  border-radius: 30px;
  margin-left: 0.5rem;
  padding: 0.3rem 1.2rem;
}

.react-select-container {
  position: relative;
  z-index: 1000;
}

.smallAreaChartContainer text {
  display: none !important;
}

.smallAreaChartContainer .apexcharts-inner {
  /* max-height: 45px;
  height: 45px; */
  transform: translate(12px, 8px);
}

.smallAreaChartContainer line {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #353535;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffffff50;
}

.customScrollbar::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.customScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.customScrollbar-light::-webkit-scrollbar {
  background-color: #b9e5fa;
}

.customScrollbar-dark::-webkit-scrollbar {
  background-color: #353535;
}

#react-joyride-step-0 span,
.react-joyride__overlay,
.react-joyride__spotlight {
  z-index: 10000 !important;
}

.__floater,
.__floater__open {
  z-index: 10001 !important;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.apexcharts-reset-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(1) !important;
  padding: 0 !important;
  margin-right: 0 !important;
  margin-left: 4px !important;
  width: 29px !important;
  height: 29px !important;
}

.apexcharts-reset-icon {
  margin-top: 1px !important;
}

.node {
  font: 300 10px "Poppins";
  cursor: pointer;
}

.node:hover {
  fill: #bbbbbb;
}

.link {
  stroke: steelblue;
  stroke-opacity: 0.4;
  fill: none;
  pointer-events: none;
}

.node:hover,
.node--source,
.node--target {
  font-weight: 700;
}

.node--source {
  fill: #2ca02c;
}

.node--target {
  fill: #00d9a5;
}

.link--source,
.link--target {
  stroke-opacity: 1;
  stroke-width: 2px;
}

.link--source {
  stroke: #00d9a5;
}

.link--target {
  stroke: #2ca02c;
}

.apexcharts-treemap {
  .apexcharts-series {
    .apexcharts-data-labels .apexcharts-datalabel tspan:first-child {
      font-size: 0.8rem !important;
    }
  }
}

#crypto-insight-chart {
  .apexcharts-point-annotations {
    image {
      clip-path: inset(0% round 50%);
      cursor: pointer;
    }
  }
}
