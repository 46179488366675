.sentimentGaugeWrapper {
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .sentimentNotBuyText {
    position: absolute;
    left: 0px;
    font-size: 10px;
    font-weight: 100;
    width: 100%;
    text-align: left;
  }

  .sentimentBuyText {
    position: absolute;
    right: 0;
    font-size: 10px;
    font-weight: 100;
    width: 100%;
    padding-right: 33px;
    text-align: right;
  }

  .sentimentBotText {
    position: absolute;
    right: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-right: 40px;
    text-align: right;
  }

  .sentimentNotBotText {
    position: absolute;
    left: 0;
    bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    width: 100%;
    padding-left: 30px;
    text-align: left;
  }

  .sentimentValue {
    position: absolute;
    right: 0;
    font-size: 11px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
}

.statisticsGaugeWrapper {
  width: 100%;
  max-width: 100px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;

  svg {
    width: 80px;
    height: 52px;
    margin-top: -14px;
  }

  .statisticsValues {
    position: relative;
    width: 100%;
  }

  // .statisticsValue {
  //   position: absolute;
  //   right: 0;
  //   bottom: -5px;
  //   font-size: 10px;
  //   font-weight: 500;
  //   width: 100%;
  //   text-align: center;
  // }
}
