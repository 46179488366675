.assetsWrapper {
  min-height: 500px;

  h2 {
    font-size: 0.7rem;
    font-weight: 300;
    line-height: 1.3;
  }

  h3 {
    font-size: 0.65rem;
    font-weight: 100;
  }

  h6 {
    font-size: 0.7rem;
    font-weight: 300;
  }

  tr {
    border-bottom: 1px solid #535353;
  }

  th {
    font-weight: 100;
    font-size: 0.7rem;
    height: auto;
    min-height: 40px;
    border-bottom: none;
  }

  .cryptoCardChart {
    width: 160px;
    height: 60px;

    div {
      &:first-of-type {
        min-height: 60px !important;
      }
    }

    svg {
      height: 60px;
    }
  }

  tbody th,
  tbody td {
    padding: 0 !important;
  }
}

.headCell {
  white-space: pre-wrap;

  p {
    font-size: 0.8rem !important;
    font-weight: 500 !important;
    line-height: 1.3 !important;
  }
}

.assetCell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 0;

  .coinLogo {
    width: 36px;
    height: 36px;
    margin-right: 6px;
    margin-left: 3px;
    border-radius: 50%;
    will-change: transform;
    transition: transform 0.2s linear;
  }

  h2 {
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1.3;
  }

  h3 {
    font-size: 0.65rem;
    font-weight: 100;
  }
}

.coinsTopInfluencers {
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    margin: 0 0.03rem;
  }
}

.blurRow {
  filter: blur(6px);
  pointer-events: none;
}

.fullAccessBTN {
  position: absolute;
  top: 530px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.redPulse {
  background: rgba(255, 82, 82, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  height: 8px;
  width: 8px;
  transform: scale(1);
  animation: pulse-red 2s infinite;
}

.greenPulse {
  background: rgba(51, 217, 178, 1);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  height: 8px;
  width: 8px;
  transform: scale(1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
