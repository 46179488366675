.fab_button {
  position: fixed !important;
  right: 100px;
  bottom: 15px;
  height: 60px;
  width: 60px;
  z-index: 1000;
  min-width: 60px !important;
  min-height: auto !important;
  padding: 0.5rem !important;
  border-radius: 50% !important;
  box-shadow: 0 0 12px rgba(212, 212, 212, 0.5) !important;
  transition: all 0.3s !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
