.influencerCardWrapper {
  position: relative;
  height: 100%;
  height: 78px;
  max-width: 100%;
}

.influencerCard {
  cursor: pointer;
  border-radius: 13px;
  height: 100%;
  background-size: contain !important;

  a {
    padding: 1rem 0 1rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 13px;
    margin-right: 25px;
    height: 100%;
  }

  .influencerCardContent {
    margin-left: 0.4rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h1 {
      font-size: 0.79rem;
      font-weight: 300;
      width: 94px;
      overflow: hidden;
      white-space: nowrap;
    }

    h2 {
      font-size: 0.58rem;
      font-weight: 100;
    }
  }

  .moreButton {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: 0;
    width: 25px;
    height: 100%;
    border-radius: 0 13px 13px 0;
    display: flex;
    align-items: flex-start;
    padding: 0;
    padding-top: 8px;
    z-index: 1;
  }

  .pinStatus {
    position: absolute;
    bottom: 10px;
    right: 5px;
    z-index: 0;
  }
}

.popover {
  position: absolute;
  top: -100%;
  min-height: 78px;
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    text-transform: none;
    font-size: 0.7rem;
    padding: 0.15rem 0;
    font-weight: 100;
    justify-content: flex-start;

    svg {
      width: 40px;
    }
  }
}

.shareContainer {
  padding-top: 1rem;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 0;
      border-radius: 15px;
      margin: 0 8px;
      min-width: auto;

      img {
        width: 55px;
      }
    }
  }

  p {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    font-weight: 100;
  }
}

.filterContainer {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 1.5rem;
  padding-top: 2.7rem;

  .filtersHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    h6 {
      flex: 1;
      font-weight: 100;
      text-align: center;
    }
  }

  .filterItems {
    min-height: 45vh;
    margin-top: 2rem;
  }
}

.sort_card_wrapper {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 10px;
    display: none;
  }
}

.influencersToolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

@media (min-width: 1100px) {
  .filterContainer {
    display: flex;
    align-items: center;

    div {
      &:first-of-type {
        min-width: 50%;
      }
    }
  }

  .sort_card_wrapper {
    &::after {
      display: block;
    }
  }
}
