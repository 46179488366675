.newsRssImage {
  border-radius: 10px;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    object-fit: cover;
    object-position: 0 0;
  }

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 40%;
      height: 40%;
    }
  }
}

.newsTweetImage {
  max-width: 400px;
  margin: auto;
  margin-top: 0.5rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    z-index: 2;
  }

  div {
    width: 267px;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    padding: 2rem 0;
    margin-left: 0 !important;

    svg {
      width: 20%;
      height: 20%;
    }
  }
}

@media (min-width: 1100px) {
  .newsTweetImage {
    margin-left: 0;

    div {
      margin-left: 2.3rem !important;
    }
  }
}
