.newsGallery {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  position: relative;
  padding: 0.6rem;
  border-radius: 8px;

  .newsGalleryTitle {
    display: flex;
    align-items: center;

    h6 {
      font-size: 0.8rem;
      font-weight: 500;
      margin: 0 8px;
    }

    span {
      font-weight: 100;
      white-space: nowrap;
    }
  }

  p {
    font-weight: 100;
    margin-top: 5px;
    font-size: 0.8rem !important;
    font-weight: 500;
    display: block;
    cursor: pointer;
    text-align: left !important;
  }

  .newsGalleryInfo {
    display: flex;
    margin-top: 0.5rem;

    p {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      font-weight: 100;
      text-align: left;
      margin-right: 10px;

      svg {
        margin-right: 3px;
      }
    }
  }

  .newsGalleryMedia img {
    margin: 0;
  }
}

@media (min-width: 1100px) {
}
