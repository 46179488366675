.planReviewContainer {
  min-height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  .planReviewWrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: auto;

    .planReviewSubscription {
      width: 45%;
      margin-left: auto;
      margin-right: auto;
      padding: 1.5rem 2rem;
      border-radius: 24px;
      transition: all 0.3s;

      .planReviewContent {
        margin-bottom: 1rem;
        border-bottom: 1px solid;
        padding-bottom: 1rem;
      }

      .planHeader {
        border-bottom: 1px solid #ffffff29;
        margin-bottom: 1.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 1rem;

        .planHeaderTitle {
          display: flex;
          flex-direction: column;

          h5 {
            align-self: flex-start;
          }

          p {
            font-size: 0.75rem;
          }
        }

        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        // p {
        //   margin-bottom: 1rem;
        //   font-size: 0.7rem;
        //   font-weight: 100;
        // }
      }

      .planDescription {
        margin-bottom: 2rem;

        .planDescriptionItem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
        }
      }

      .planPrice {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .planPriceWrapper {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .planPriceItem {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
