.securityWrapper {
  padding: 1.5rem;
  position: relative;
  overflow: hidden;

  .securityCircle {
    width: 450px;
    height: 450px;
    position: absolute;
    right: 10%;
    top: 10%;
    z-index: -1;
    filter: blur(60px);
    border-radius: 50%;
    background-color: rgba(67, 150, 126, 0.08);
  }

  .stars_1 {
    position: absolute;
    left: -10%;
    top: 0;
  }

  .stars_2 {
    position: absolute;
    left: -10%;
    top: 20%;
  }

  .stars_3 {
    position: absolute;
    left: -10%;
    top: 60%;
  }

  .securityItem {
    background-color: transparent;
    margin: 1rem 0;

    h6 {
      padding-left: 26px;
      position: relative;
      font-size: 1rem;
      font-weight: 500;

      &::before {
        content: "";
        width: 14px;
        height: 14px;
        position: absolute;
        left: 0;
        top: calc(50% - 7px);
        border-radius: 50%;
        background-color: #00aeef;
      }
    }

    p {
      font-weight: 100;
      font-size: 0.9rem;
      line-height: 1.8;
    }
  }
}

@media (max-width: 1000px) {
  .stars_1,
  .stars_2,
  .stars_3 {
    opacity: 0.4;
  }
}
