.accountWrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  button {
    width: 70%;
    margin: auto;
    margin-bottom: 3rem;
    border-radius: 30px;
  }
}

.accountContent {
  flex: 1;

  .accountProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    h1 {
      font-weight: 100;
      margin-top: 1rem;

      svg {
        margin-left: 0.6rem;
      }
    }
  }

  .accountPlan {
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
    padding-bottom: 0.5rem;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      gap: 6rem;

      p {
        font-weight: 100;
      }
    }
  }

  ul {
    padding: 1rem 2rem 3rem;
    list-style: none;

    li {
      margin-bottom: 1.4rem;

      a {
        display: flex;
        align-items: center;

        svg {
          margin-right: 0.5rem;
        }

        h6 {
          font-weight: 100;
          font-size: 1rem;
        }
      }
    }
  }
}

@media (min-width: "1100px") {
  .accountContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .account-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
