.fullPageBlurContainer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease-in-out;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
