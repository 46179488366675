// ----------------- Signals -----------------

.signalWrapper {
  overflow: hidden !important;

  h2 {
    font-size: 0.7rem;
    font-weight: 300;
    line-height: 1.3;
  }

  h3 {
    font-size: 0.65rem;
    font-weight: 100;
  }

  .coinLogo {
    width: 25px;
    height: 25px;
    margin-right: 6px;
    margin-left: 10px;
    border-radius: 50%;
  }

  tbody {
    th {
      padding: 0;
    }

    td {
      padding: 6px 16px;
    }
  }
}

// ----------------- Performers -----------------

.performerWrapper {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  padding: 0 0.7rem;

  span {
    width: 30px;
    font-weight: 100;
    font-size: 0.75rem;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }

  .performerInfo {
    flex: 1;

    h5 {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.3;
    }

    h6 {
      font-size: 0.7rem;
      font-weight: 100;
    }
  }

  .performerPrice {
    h5 {
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.3;
    }

    h6 {
      font-size: 0.7rem;
      font-weight: 100;
    }
  }
}

// ----------------- Ranked Influencers -----------------

.rankedInfluencerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;

  h2 {
    font-weight: 100;
    font-size: 0.82rem;
    line-height: 18px;

    strong {
      font-weight: 500;
    }
  }
}

// ----------------- chat Banner Wrapper -----------------

.chatBannerWrapper {
  min-height: 500px;

  .chatBannerAction {
    display: flex;
    align-items: center;
  }

  .chatBannerActionInfo {
    display: flex;
    align-items: center;
  }

  .agentItem {
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 122px;

    img {
      width: 75px;
      height: 75px;
    }
  }
}

@media (max-width: 1100px) {
  .chatBannerWrapper {
    min-height: 500px;

    h2 {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    .chatBannerAction {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 100%;
        margin-bottom: 0.5rem;
      }
    }

    .chatBannerActionInfo {
      width: 100%;
      margin-top: 0.8rem;
      justify-content: center;
    }
  }
}

// ----------------- Movers Marquee -----------------

.moversMarquee {
  height: 80px;
  .coinContainer {
    display: flex !important;
    align-items: center;
    padding: 0.5rem 0;

    img {
      width: 28px;
      height: 28px;
      padding: 0.02rem;
      border-radius: 50%;
      object-fit: contain;
      margin-right: 0.4rem;
    }

    .coinInfo {
      width: 100%;

      h2 {
        font-size: 0.65rem;
        font-weight: 400;
      }

      h4 {
        font-size: 0.56rem;
        margin-right: 0.8rem;
        font-weight: 100;
      }

      span {
        font-size: 0.56rem;
      }
    }
  }
}
