/* Rss Card */

.newsRssCardWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 50px;
  position: relative;
  overflow-x: hidden;
  width: 100%;

  .newsCardWrapper {
    padding: 0 !important;
    display: flex;
    width: 100%;
  }

  .newsCardTitle {
    flex: 1;
    display: flex;
    align-items: flex-start;

    h6 {
      font-size: 1rem;
      font-weight: 500;
      margin-right: 8px;
    }

    span {
      font-weight: 100;
    }
  }

  p {
    font-weight: 100;
    margin-top: 5px;
    font-size: 0.8rem;
    font-weight: 500;
    display: block;
    padding-right: 10px;
  }

  .newsRssCardFooter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0.5rem;

    span {
      font-weight: 400;
      font-size: 0.7rem;
    }
  }
}

@media (max-width: 1100px) {
  .newsRssCardWrapper {
    .newsCardWrapper {
      flex-direction: column-reverse;
    }

    .newsCardTitle {
      flex: 1;
      display: flex;
      align-items: flex-start;
      margin-top: 0.5rem;

      h6 {
        font-size: 1rem;
        font-weight: 500;
        margin-right: 8px;
      }

      span {
        font-weight: 100;
      }
    }

    p {
      font-weight: 100;
      margin-top: 5px;
      font-size: 0.8rem;
      font-weight: 500;
      display: block;
      padding-right: 10px;
    }

    .newsRssCardFooter {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 0.5rem;

      span {
        font-weight: 400;
        font-size: 0.7rem;
      }
    }
  }
}
