.filterNewsMenu {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2rem;

  .sortingFilter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.7rem;
    // max-width: 600px;

    h6 {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
    }

    div {
      flex: 1;

      button {
        border-radius: 8px;
        margin-right: 0.5rem;
        border: 1px solid #eee;
      }
    }
  }

  .rssSortingFilter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 0.7rem;
    // max-width: 600px;

    h6 {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
    }

    div {
      flex: 1;

      button {
        border-radius: 8px;
        margin-right: 0.5rem;
        border: 1px solid #eee;
        text-transform: none;
      }
    }
  }

  .coinsListWrapper {
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    // max-width: 600px;

    h6 {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
    }

    .coinsList {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 100%;

      .coinItem {
        display: flex;
        align-items: center;
        border: 1px solid #666;
        border-radius: 5px;
        padding: 0.3rem 0.3rem 0.3rem 0.5rem;
        margin: 0 0.2rem 0.4rem 0;

        img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
        }

        span {
          font-size: 12px;
          font-weight: 100;
          margin: 0;
          margin-left: 0.4rem;
        }
      }
    }
  }

  .filterNewsAction {
    display: flex;
    align-items: center;
    justify-content: center;
    // max-width: 600px;
    margin-top: 50px;

    button {
      text-transform: none;
      margin: 0 0.5rem;
      padding: 0.3rem 1.5rem;
      border-radius: 30px;
      flex: 1;
    }
  }
}

.sortFilters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1.3rem 0 0.5rem;
  padding-bottom: 0.5rem;

  svg {
    margin-right: 1rem;
  }

  h3 {
    margin-right: 0.5rem;
  }

  p {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
}

.activeFilters {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5rem 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;

  h3 {
    margin-right: 0.5rem;
  }

  span {
    margin-right: 0.1rem;
    padding: 0.2rem 0.6rem;
    border-radius: 4px;
  }
}

@media (min-width: 1100px) {
  .filterNewsMenu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 2rem;

    div {
      &:first-of-type {
        min-width: 50%;
      }
    }
  }
}
