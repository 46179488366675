.faqWrapper {
  padding: 1.5rem;
  position: relative;
  overflow: hidden;

  .stars_1 {
    position: absolute;
    left: -10%;
    top: 0;
  }

  .stars_2 {
    position: absolute;
    left: -10%;
    top: 20%;
  }

  .stars_3 {
    position: absolute;
    left: -10%;
    top: 60%;
  }
}

@media (max-width: 1000px) {
  .stars_1,
  .stars_2,
  .stars_3 {
    opacity: 0.4;
  }
}
