.influencerImage {
  border-radius: 50%;
  object-fit: cover;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
