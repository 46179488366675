.assets_management {
  position: relative;
  min-height: 80vh;
  padding-bottom: 5rem;

  .eth_logo {
    width: 110px;
    position: absolute;
    left: 0%;
    bottom: -5%;
    z-index: -1;
    filter: blur(2px);
    animation: ethLogoAnimation 1.3s;
    animation-iteration-count: 1;
  }

  .stars {
    position: absolute;
    right: -8%;
    top: 20%;
    filter: blur(2px);
    opacity: 0.7 !important;
    transform: rotate(100deg);
  }

  h1 {
    font-weight: 700;
    font-size: 2.3rem;
  }

  .assets_items {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;

    .assets_item {
      flex: 1;
      min-height: 190px;
      position: relative;
      cursor: pointer;
      transition: all 0.3s;

      h2 {
        font-size: 1.3rem;
        font-weight: 700;
      }

      p {
        font-weight: 100;
        font-size: 0.95rem;
      }

      img {
        position: absolute;
        right: 15px;
        bottom: 20px;
        width: 60px;
        transform: rotate(10deg);
        transition: all 0.3s;
      }

      &:hover {
        transform: scale(1.01);

        img {
          transform: rotate(0);
        }
      }
    }
  }
}

.demo_modal {
  padding: 2rem 1rem;
  width: 60%;
  min-width: 360px;
  position: absolute;
  top: 28%;
  left: 30%;
  box-shadow: 16px;
  border-radius: 24px;
  transform: translate(-20%, -25%);

  .demo_modal_button {
    position: absolute;
    top: 5%;
    right: 2% !important;
  }
}

@media (max-width: 1100px) {
  .assets_management {
    position: relative;
    min-height: 73vh;

    .eth_logo {
      display: none;
    }

    h1 {
      font-size: 1.23rem;
    }

    p {
      font-size: 0.85rem;
    }

    .assets_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;

      .assets_item {
        min-width: 320px;
      }
    }
  }

  .demo_modal {
    top: 28%;
    left: 4%;
    padding: 0.6rem;
    transform: translate(0%, 0%);
  }
}

@keyframes ethLogoAnimation {
  from {
    transform: translateX(-90px) translateY(40px) rotateZ(50deg);
  }
  to {
    transform: translateX(0) translateY(0) rotateZ(0);
  }
}
