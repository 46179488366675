.cryptoTweets {
  flex-grow: 1;
  background: transparent;
  height: 1800px;
  overflow-y: auto;
  margin-top: 1rem;
}

@media (max-width: 1100px) {
  .cryptoTweets {
    background: transparent;
    height: auto;
    overflow-y: auto;
  }
}
