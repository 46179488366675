.primary_button {
  font-weight: 500;
  border-radius: 23px;
  text-decoration: none;
  padding: 14px 65px !important;
  text-transform: none !important;
  border-radius: 23px !important;
  transition: all 0.3s;
  z-index: 100;
  box-shadow: 10px -4px 40px 10px #00406d !important;
  white-space: nowrap;
}

.default_button {
  text-transform: none !important;
  border-radius: 30px !important;
  font-weight: 300 !important;
  margin-top: 20px;
  background-color: #fff !important;
  color: #272525 !important;
}

.secondary_button {
  text-transform: none !important;
  border-radius: 30px !important;
  font-weight: 300 !important;
  max-width: 300px !important;
  display: block !important;
  width: 100% !important;
  margin: auto !important;
  margin-top: 20px !important;
}

.card_header_button {
  text-transform: none !important;
  border-radius: 30px !important;
  font-weight: 100 !important;
  padding: 0.1rem 0.4rem !important;
  font-size: 0.65rem !important;
  margin-left: 4px !important;
}

.view_more {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 28px;
  padding: 0;
  text-align: center;
  z-index: 1000;

  button {
    text-align: center;
    text-transform: none;
    font-weight: 100;
    font-size: 0.7rem;
    display: flex;
    align-items: center;
  }
}
.fullChartBtn {
  width: fit-content;
  background-color: initial;
  box-shadow: inset 0 0 0 1px var(---border, var(--porcelain));
  border-radius: 4px;
  display: flex; /* Enable flexbox */
  flex-direction: row; /* Horizontal alignment */
  margin: 0; /* Remove margin if Typography adds any */
  max-height: 30px;
}

.fullChartBtn__wrapper {
  width: 5px;
  height: 30px;
  display: flex;
  border-radius: 4px;
  margin-right: 10px;
}

.fullChartBtn__visibel {
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(211, 211, 211, 0.53);
    border-radius: 4px;
  }
}

@media (max-width: 1100px) {
  .primary_button {
    padding: 14px 5px !important;
  }
}
