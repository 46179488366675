.cryptoHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 0 2rem;

  img {
    width: 55px;
    margin-right: 0.5rem;
    border-radius: 50%;
  }

  div {
    flex: 1;

    h1 {
      font-size: 1.1rem;
    }

    h2 {
      font-size: 0.85rem;
      font-weight: 300;
    }

    h3 {
      font-size: 1.3rem;
    }

    h4 {
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
}

.signals_container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .signal_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .guage_footer {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;

    h5 {
      font-weight: 100;
      font-size: 0.8rem;
    }

    h6 {
      font-weight: 700;
      font-size: 0.9rem;
    }
  }
}

.signals_table {
  margin-top: 1rem;

  h5 {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid;
  }

  th,
  td {
    border: none !important;
    padding: 10px 0;
  }
}

.exchanges_table {
  th,
  td {
    border: none !important;
    padding: 10px 0;
  }
}

.crypto_influencer {
  display: flex;
  align-items: center;
  border-radius: 8px;

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
}

.chartHandlers {
  max-width: 400px;
  margin: 2rem auto 1rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h6 {
    cursor: pointer;
    font-weight: 100;
    font-size: 0.8rem;
    padding: 1px 19px;
    border-radius: 30px;
  }
}

.linechart_tooltip {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 130px;
  border: none;
  box-shadow: none;

  h4 {
    font-weight: 300;
    font-size: 0.82rem;
    width: 100%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.4rem;
    width: 100%;

    h5 {
      font-weight: 300;
    }

    h6 {
      font-weight: 100;
      min-width: 45px;
      font-size: 0.7rem;
    }
  }
}

.coinSignalWrapper {
  height: 100%;
  position: relative;

  .comingSoonWrapper {
    position: absolute;
    left: -2.5%;
    top: -2.5%;
    width: 105%;
    height: 105%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
  }
}

@media (max-width: 1100px) {
  .cryptoTabs {
    display: none;
  }

  .signals_container {
    flex-direction: column;

    .signal_container {
      margin: 2rem auto 0;
    }
  }
}
