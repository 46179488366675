.signals_titles {
  position: relative;
  width: 450px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  h6,
  p {
    font-weight: 600;
  }
}

.signals_wrapper {
  position: relative;
  min-height: 240px;
  width: 450px;
  margin: auto;
  overflow: hidden;

  .center_guage {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 16%;
    z-index: 1;
  }

  .right_guage {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: -8.3%;
    top: 1%;
    z-index: 0;
    transform: rotate(50deg);
  }

  .left_guage {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: -18.8%;
    top: 20%;
    z-index: 0;
    transform: rotate(-50deg);
  }
}

.signals_footer {
  position: relative;
  width: 190px;
  padding-right: 10px;
  margin: auto;
  margin-top: -25px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.7rem;
  }
}

@media (max-width: 1200px) {
  .signals_titles {
    width: 300px;
    justify-content: space-between;
  }

  .signals_wrapper {
    min-height: 190px;
    width: 300px;

    .center_guage {
      left: 16%;
      top: 16%;
    }

    .right_guage {
      right: -15%;
      top: 30%;
    }

    .left_guage {
      left: -21%;
      top: 40%;
    }
  }

  .signals_footer {
    width: 150px;
    margin-top: -5px;

    span {
      font-size: 0.6rem;
    }
  }
}
