.influencerInfo {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  height: 180px;
  display: flex;
  align-items: flex-end;
  border-radius: 13px;

  .influencerInfoContent {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-bottom: -10px;

    h1 {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1;
    }

    h2 {
      font-weight: 100;
      font-size: 0.75rem;
    }
  }

  .influencerInfoActions {
    min-height: 65px;
    padding-right: 15px;
    display: flex;
    align-items: center;

    button {
      margin-left: 3px;
    }
  }
}

.coinsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;

  div {
    margin-right: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 33px;
      height: 33px;
      object-fit: cover;
    }

    span {
      margin-top: 5px;
      font-weight: 100;
    }
  }

  p {
    font-weight: 100;
    margin-left: 5px;
  }
}

.accountStatisticsWrapper {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
}

.accountStatistics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.2rem;
  min-height: 85%;

  .accountStatistics_card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      &:first-of-type {
        display: flex;
        flex-direction: column;
      }

      &:last-of-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        padding: 0.5rem;
        background-color: #00AEEF;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      }
    }

    h6 {
      font-size: 0.85rem;
      font-weight: 100;
    }

    h5 {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

.shareContainer {
  padding-top: 1rem;
  padding-bottom: 80px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      padding: 0;
      border-radius: 15px;
      margin: 0 8px;
      min-width: auto;

      img {
        width: 55px;
      }
    }
  }

  p {
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    font-weight: 100;
  }
}

.breadcrumb {
  display: inline;

  a {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    font-weight: 100;
    font-size: 0.9rem;
  }
}

@media (max-width: 1100px) {
  .influencerInfo {
    position: relative;

    .influencerInfoActions {
      min-height: 45px;
      padding-right: 15px;
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;

      button {
        margin-left: 3px;
      }
    }
  }
}
