.fade-in {
  opacity: 0;
  animation: fadeIn 5s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
