.fullGaugeWrapper {
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // .fullGaugeNotBuyText {
  //   position: absolute;
  //   left: 0px;
  //   font-size: 10px;
  //   font-weight: 100;
  //   width: 100%;
  //   padding-left: 34px;
  //   text-align: left;
  // }

  // .fullGaugeBuyText {
  //   position: absolute;
  //   right: 0;
  //   font-size: 10px;
  //   font-weight: 100;
  //   width: 100%;
  //   padding-right: 43px;
  //   text-align: right;
  // }

  .fullGaugeValue {
    position: absolute;
    right: 0;
    bottom: -10px;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }
}

.halfGaugeWrapper {
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .halfGaugeNotBuyText {
    position: absolute;
    left: 0px;
    font-size: 10px;
    font-weight: 100;
    width: 100%;
    padding-left: 34px;
    text-align: left;
  }

  .halfGaugeBuyText {
    position: absolute;
    right: 0;
    font-size: 10px;
    font-weight: 100;
    width: 100%;
    padding-right: 43px;
    text-align: right;
  }

  .halfGaugeValue {
    position: absolute;
    // right: 0;
    // bottom: 0;
    font-size: 13px;
    font-weight: 500;
    width: 50px;
    text-align: center;
  }
}
