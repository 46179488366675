.successPaymentContainer {
  .successPayment {
    padding-top: 2rem;
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    transition: all 0.3s;
  }
}

.featuresWrapper {
  margin-top: 5rem;
}
