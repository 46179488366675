.agentInfo {
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 1rem;
  padding-left: 0.5rem;
  margin-top: 1rem;

  .agentInfoHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .agentInfoContent {
    display: flex;
    align-items: flex-start;

    .agentInfoText {
      padding: 0.5rem;
      line-height: 1.8;
      font-size: 0.95rem;
      font-weight: 300;
    }
  }

  .agentInput {
    width: 80%;
    margin-top: 1rem;
  }

  h4 {
    margin-left: 1rem;
  }

  p {
    font-weight: 100;
    margin-top: 1rem;
  }
}

@media (max-width: 1100px) {
  .agentInfo {
    padding: 0.5rem;
  }
}

@media (max-width: 1100px) {
  .agentInfoContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .agentInfoText {
      padding: 0.5rem;
      line-height: 1.8;
      font-size: 0.95rem;
      font-weight: 100;
    }
  }
}

// ----------- Agent Response -----------

.agent_response_wrapper {
  border: 1px solid #eee;
  border-radius: 12px;
  padding: 1rem;
  min-height: auto;

  .agentText {
    padding: 0.5rem;
    line-height: 1.8;
    font-size: 0.95rem;
    font-weight: 100;
  }

  .agentInput {
    width: 80%;
    margin-top: 1rem;
  }
}

.agentBreadCrumb {
  a,
  p {
    font-weight: 100;
    font-size: 0.9rem;
  }
}

@media (max-width: 1100px) {
  .agent_response_wrapper {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  .agentBreadCrumb {
    margin-bottom: 1rem !important;
  }
}

.coinLogo {
  width: 25px;
  height: 25px;
  margin-right: 6px;
  margin-left: 10px;
  border-radius: 50%;
}
