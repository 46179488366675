@import "/src/utils/variables/variables.scss";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .menu-icon {
      display: flex;
      align-items: center;

      & > button {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;

        &:hover {
          background-color: #ffffff20;
          transition: all ease-in-out 0.3;
        }

        & > svg {
          position: relative;
        }
      }
    }

    & > a > img {
      margin: 0 0.5rem;
    }

    .notification-icon {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    .switch {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0.5rem;

      input {
        display: none;
      }

      &__container {
        position: relative;
        width: 38px;
        height: 20px;
        border-radius: 2rem;
        background-color: $bg-gray;
        transition: all ease-in-out 0.3s;

        .switch__anchor {
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          position: absolute;
          transition: all ease-in-out 0.2s;
        }
      }
    }
  }

  .navigation {
    display: flex;
    align-items: center;

    & > nav {
      & > ul {
        display: flex;

        & > li {
          position: relative;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0.5rem 0;
          transition: all linear 0.3s;
          margin: 0 1rem;

          & > a {
            display: flex;
            align-items: center;
          }

          p {
            transition: all linear 0.2s;
          }
        }

        .products {
          position: relative;

          span {
            display: flex;
            align-items: center;
          }

          .products__list {
            position: absolute;
            top: 35px;
            right: 0;
            width: auto;
            list-style-type: none;
            border-radius: 8px;
            z-index: 101;
            padding: 0;
            overflow: hidden;
            transition: all 0.3s;

            & > li {
              padding: 0;

              a {
                width: 100%;
                padding: 0.7rem 1rem;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 0.5rem;
                transition: all ease-in-out 0.3s;

                &:hover {
                  background-color: #ffffff6d;
                }
              }
            }
          }
        }
      }
    }

    .register {
      display: flex;
      align-items: center;

      .btn {
        background-color: $secondary-dark;
        color: $green-dark;
        font-weight: 700;
        padding: 0.63rem 1.3rem;
        border: none;
        outline: none;
        border-radius: 1rem;
        cursor: pointer;
        margin-left: 0.3rem;
        transition: all ease-in-out 0.2s;

        &:hover {
          background-color: $green-dark;
          color: $secondary-dark;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;

    .logo {
      width: 100%;
      display: flex;

      & > a > img {
        margin: 0 0.2rem;
      }

      .switch {
        margin-left: 0.2rem;
      }
    }

    .navigation {
      display: flex;
      align-items: center;

      & > nav {
        & > ul {
          display: flex;

          & > li {
            margin-right: 0rem;
            font-size: 0.8rem;
            white-space: nowrap;
          }
        }
      }

      .register {
        display: flex;
        align-items: center;

        .btn {
          background-color: $secondary-dark;
          color: $green-dark;
          font-weight: 700;
          padding: 0.63rem 1.3rem;
          border: none;
          outline: none;
          border-radius: 1rem;
          cursor: pointer;
          margin-left: 0.3rem;
          transition: all ease-in-out 0.2s;

          &:hover {
            background-color: $green-dark;
            color: $secondary-dark;
          }
        }
      }
    }
  }
}
