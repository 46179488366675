.authImage {
  height: 70vh;
  margin-top: 80px;
  border-radius: 30px 0 0 30px;
  transform: translateX(30px);
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top center 100px !important;
  position: relative;
}

.authWrapper {
  height: 100%;
  min-height: 80vh;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
  align-items: center !important;

  .authLogo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.6s;

    img {
      width: 90% !important;
    }
  }
}

.loginWrapper {
  height: 100%;
  min-height: 90vh;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  position: relative;
  z-index: 1;

  .linkWrapper {
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    p {
      font-weight: 300;
    }

    a {
      margin-top: 5px;
      font-weight: 500;
      text-decoration: none;
      width: fit-content;
    }
  }
}

.verificationMessage {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 150px;
    margin-top: 70px;
  }

  p {
    margin-top: 2rem;
  }

  a {
    font-weight: 500;
    margin-top: 0.1rem;
    text-decoration: none;
  }
}

.authButton {
  border-radius: 30px !important;
  position: relative;
  font-size: 1.1rem !important;
  z-index: 100 !important;
  width: 200px !important;
  padding: 0 !important;
  min-width: auto !important;
  overflow: hidden;
  box-sizing: border-box;

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: -100%;
    top: 0;
    border-radius: 30px;
    z-index: 1000;
    transition: all 4s linear;
  }
}

.sideShape_1 {
  position: absolute;
  left: -10%;
  top: 0%;
  width: 80px;
  opacity: 0;
  filter: blur(1px);
  transition: all 0.8s;
}

.sideShape_1_animation {
  left: 0;
  top: 6%;
  opacity: 1;
  animation: normalOpacityAnimation 1.5s ease-out 1s;
}

.sideShape_2 {
  width: 55px;
  position: absolute;
  right: -10%;
  top: 28%;
  opacity: 0;
  filter: blur(2px);
  transform: rotate(50deg);
  transition: all 0.6s;
}

.sideShape_2_animation {
  right: 2%;
  top: 18%;
  opacity: 1;
  animation: normalOpacityAnimation 3s linear;
}

.sideShape_3 {
  width: 50px;
  position: absolute;
  right: -30%;
  top: 70%;
  opacity: 0;
  filter: blur(2px);
  transform: rotate(50deg, 30deg);
}

.sideShape_3_animation {
  right: 0;
  top: 60%;
  opacity: 1;
  animation: normalOpacityAnimation 1s ease-in-out;
}

.bIcon_1 {
  width: 40px;
  position: absolute;
  right: 20%;
  top: 19%;
  opacity: 0;
  filter: blur(1px);
  transform: rotate(80deg);
  transition: all 0.6s;
}

.bIcon_1_animation {
  right: 14%;
  top: 10%;
  opacity: 1;
  animation: normalOpacityAnimation 3s linear 0.5s;
}

.bIcon_2 {
  position: absolute;
  left: -7%;
  top: 69%;
  opacity: 0;
  filter: blur(2px);
  transition: all 0.6s;
}

.bIcon_2_animation {
  left: 0;
  top: 61%;
  opacity: 1;
  animation: normalOpacityAnimation 2.5s ease-in 0.6s;
}

.smallCircle_1 {
  position: absolute;
  top: 90%;
  left: 10%;
  width: 20px;
  height: 20px;
  opacity: 0;
  border-radius: 50%;
  filter: blur(2px);
  background-color: #04aa8e;
  transition: all 2.6s;
}

.smallCircle_2 {
  position: absolute;
  bottom: 25%;
  right: 12%;
  width: 12px;
  height: 12px;
  opacity: 0;
  border-radius: 50%;
  filter: blur(2px);
  background-color: #04aa8e;
  transition: all 1.6s;
}

.stars_1_animation {
  animation: normalOpacityAnimation 1s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.stars_4_animation {
  animation: normalOpacityAnimation 1s cubic-bezier(0.86, 0, 0.07, 1);
}

.mainCirclesLogo {
  position: absolute;
  width: 70%;
  height: 50%;
  top: 20%;
  left: 15%;
  transition: all 0.6s;

  .coinLogo {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 1s;
    position: absolute;
    top: calc(50% + 80px);
    left: calc(50% - 60px);
    width: 120px;
    height: 120px;
    opacity: 0;
    z-index: 10;
    animation: coinAnimation 4s linear infinite;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .circle_1 {
    width: 330px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    right: calc(50% - 165px);
    top: 47%;
    border: 1px solid #04aa8e;
    opacity: 0;
    transition: all 1.5s;
  }

  .circle_2 {
    width: 330px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    right: calc(50% - 165px);
    top: 47%;
    border: 1px solid #04aa8e;
    opacity: 0;
    transition: all 1.5s;
  }

  .circle_3 {
    width: 330px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    right: calc(50% - 165px);
    top: 47%;
    border: 1px solid #04aa8e;
    opacity: 0;
    transition: all 1.5s;
  }
}

.stars_1 {
  width: 70%;
  position: absolute;
  left: 0%;
  top: 70%;
  opacity: 0;
  transform: rotate(40deg);
  transition: all 0.4s;
  z-index: 0;
}

.stars_2 {
  width: 70%;
  position: absolute;
  left: -30%;
  top: 20%;
  opacity: 0;
  transform: rotate(160deg);
  transition: all 0.5s;
  z-index: 0;
}

.stars_3 {
  width: 50%;
  position: absolute;
  right: -20%;
  top: 50%;
  opacity: 0;
  transition: all 0.6s;
  z-index: 0;
}

.stars_4 {
  width: 50%;
  position: absolute;
  right: -15%;
  top: 10%;
  opacity: 0;
  transition: all 0.2s;
  z-index: 0;
}

.stars_5 {
  width: 70%;
  position: absolute;
  left: -35%;
  top: 65%;
  opacity: 0;
  transform: rotate(160deg);
  transition: all 0.3s;
  z-index: 0;
}

.line {
  flex: 1;
  height: 1px;
}

@keyframes coinAnimation {
  0% {
    top: calc(50% - 80px);
  }
  50% {
    top: calc(50% - 100px);
  }
  100% {
    top: calc(50% - 80px);
  }
}

@keyframes normalOpacityAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1100px) {
  .loginWrapper {
    flex-direction: column !important;
    justify-content: flex-start !important;
  }
}
