.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  outline: none;
  overflow: hidden;

  .imageContainer {
    width: 100%;
    height: 600px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .contentContainer {
    position: relative;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
    padding: 20px 32px;

    .closeButton {
      position: absolute;
      top: 8px;
      right: 8px;
    }

    .features {
      display: flex;
      flex-direction: column;
      //   justify-content: space-evenly;
      flex-grow: 1;
    }
  }
}
